.starsContainer {
    position: fixed;
    top: 0;
    left: 0;
    transform: rotate(-10deg);
}

.star {
    position: fixed;
    left: 10vw;
    top: 0px;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    background-color: white;
    animation-name: shootingStarsAnimation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    
    &:nth-child(1) {
        animation-duration: 6s;
    }
    
    &:nth-child(2) {
        left: 30vw;
        animation-duration: 10s;
    }
    
    &:nth-child(3) {
        left: 45vw;
        animation-duration: 4s;
    }
    
    &:nth-child(4) {
        left: 70vw;
        animation-duration: 8s;
    }
    
    &:nth-child(5) {
        left: 90vw;
        animation-duration: 7s;
    }
}

.star::before {
    position: absolute;
    display: block;
    content: '';
    height: 60px;
    width: 2px;
    bottom: 1px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, transparent 100%);
}

@keyframes shootingStarsAnimation {
    from {
        top: -100px;
        opacity: 1;
    }

    to {
        top: 120vh;
        opacity: 0;
    }
}