.ripplesContainer {
    bottom: 0;
    position: fixed;
    right: 0;
    transform: translate(50%, 50%);

    .ripple {
        border-radius: 50%;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.3), 0 0 32px rgba(0, 0, 0, 0.2), 0 0 48px rgba(0, 0, 0, 0.15);
        opacity: 0.75;
        position: absolute;
        transform: translate(-50%, -50%);
    }

    .ripple:nth-of-type(1) {
        background-color: rgba(176, 216, 192, 0.9);
        height: 20rem;
        width: 20rem;
        z-index: 10;

        @media (min-width: 768px) {
            height: 25rem;
            width: 25rem;
        }

        @media (min-width: 1200px) {
            height: 30rem;
            width: 30rem;
        }

        @media (min-width: 1500px) {
            height: 35rem;
            width: 35rem;
        }
    }
    
    .ripple:nth-of-type(2) {
        background-color: rgba(126, 163, 142, 0.9);
        height: 30rem;
        width: 30rem;
        z-index: 9;

        @media (min-width: 768px) {
            height: 38rem;
            width: 38rem;
        }

        @media (min-width: 1200px) {
            height: 45rem;
            width: 45rem;
        }

        @media (min-width: 1500px) {
            height: 55rem;
            width: 55rem;
        }
    }
    
    .ripple:nth-of-type(3) {
        background-color: rgba(71, 104, 83, 0.9);
        height: 40rem;
        width: 40rem;
        z-index: 8;

        @media (min-width: 768px) {
            height: 50rem;
            width: 50rem;
        }

        @media (min-width: 1200px) {
            height: 58rem;
            width: 58rem;
        }

        @media (min-width: 1500px) {
            height: 75rem;
            width: 75rem;
        }
    }
}