.headerContainer {

    .secondaryText {
        color: #c8dfd1;
        font-weight: 600;
        font-size: 0.9rem;

        @media (min-width: 1500px) {
            font-size: 0.95rem;
        }
    }

    .primaryText {
        color: #58F898;
        font-size: 5rem;
        font-weight: 700;
        letter-spacing: -0.5px;
        line-height: 4.75rem;
        margin-top: 0.25rem;
    }
}