body {
    background-color: #0a1d2e;
    overflow-x: hidden;
    margin: 0;
    font-family: 'Fira Code', monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Fira Code', monospace;
}