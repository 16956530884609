.descriptionContainer {
    margin-top: 2rem;

    @media (min-width: 900px) {
        margin-top: 1.5rem;
    }

    @media (min-width: 1500px) {
        margin-top: 1rem;
    }

    .primaryText {
        color: #C3E6D1;
        font-size: 4rem;
        font-weight: 700;
        letter-spacing: -0.5px;
        line-height: 4.25rem;

        @media (min-width: 1200px) {
            font-size: 3rem;
        }
    }

    .secondaryText {
        color: #c8dfd1;
        font-size: 0.9rem;
        line-height: 1.35rem;
        margin-top: 0.5rem;

        @media (min-width: 1500px) {
            font-size: 1rem;
        }
    }
}