.app {
    position: relative;
    background: linear-gradient(0deg, #0e2a42, #0a1d2e, #06131f);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    overflow-y: auto;
}

.contentContainer {
    width: 90%;
    z-index: 100;

    @media (min-width: 768px) {
        width: 75%;
    }

    @media (min-width: 1200px) {
        width: 60%;
    }
    
    @media (min-width: 1500px) {
        width: 50%;
    }
}